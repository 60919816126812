import React from 'react';

import { connect } from 'react-redux';
import {
  apeFetchDistributors,
  apeDeleteDistributor,
  showModal,
  addNotice,
} from '../../../actions';

import AddDistributorModal from './add';

import {
  PlusCircleIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import Loader from '../../Loader';

const DistributorAdmin = ({
  apeFetchDistributors,
  apeDeleteDistributor,
  showModal,
  distributors,
  addNotice,
  loading,
}) => {
  const [mounted, setMounted] = React.useState(null);
  const [selectedDistributor, setSelectedDistributor] = React.useState(null);

  React.useEffect(() => {
    // on mount, get the athlete
    if (!mounted) {
      setMounted(true);
      apeFetchDistributors();
    }
  }, [apeFetchDistributors, mounted]);

  const handleShowModal = (e) => {
    e.preventDefault();
    setSelectedDistributor(null);
    showModal('APE_ADD_DISTRIBUTOR');
  };

  const renderAddDistributorButton = () => {
    return (
      <div className="flex flex-row justify-center items-center">
        <button
          className="btn btn-ghost btn-sm rounded-btn"
          onClick={handleShowModal}
        >
          <PlusCircleIcon className="h-5 w-5" />
          <span className="ml-2">Add Distributor</span>
        </button>
      </div>
    );
  };

  // open the edit modal
  const onEditClick = async (e, distributorId) => {
    e.preventDefault();
    setSelectedDistributor(distributorId);
    showModal('APE_ADD_DISTRIBUTOR');
  };

  const onDistributorDeleteClick = (distributorId) => {
    window.confirm('Are you sure you want to delete this invite?') &&
      apeDeleteDistributor(distributorId);
  };

  const renderMenu = (distributor) => {
    return (
      <div className="flex flex-col items-center">
        <div className="dropdown dropdown-bottom dropdown-end hover:bg-gray-200 rounded-lg">
          <label tabIndex={0} className="cursor-pointer ">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-48"
          >
            <li>
              <div
                onClick={(e) => onEditClick(e, distributor)}
                className="cursor-pointer"
              >
                Edit
              </div>
            </li>
            <li>
              <div
                onClick={() => onDistributorDeleteClick(distributor)}
                className="cursor-pointer"
              >
                Delete
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderDistributors = () => {
    if (loading?.distributors) {
      return <Loader type="table" />;
    }

    // render users in a table
    if (distributors?.length) {
      return (
        <table className="w-full table-compact md:table-auto text-sm">
          <thead>
            <tr className="text-left">
              <th>Name</th>
              <th>Makes</th>
              <th className="w-4"></th>
            </tr>
          </thead>
          <tbody>
            {distributors?.map(({ name, _id, makes }) => {
              return (
                <tr key={_id} className="border-b border-gray-200 items-center">
                  <td className="truncate whitespace-nowrap">{name}</td>
                  <td className="truncate whitespace-nowrap">
                    {makes && (
                      <div className="flex flex-wrap gap-2">
                        {makes?.map(({ _make }, i) => (
                          <span key={i}>{_make.name}</span>
                        ))}
                      </div>
                    )}
                  </td>
                  <td className="w-4">{renderMenu(_id)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <h1>Distributors Management</h1>
      {renderAddDistributorButton()}
      {renderDistributors()}
      <AddDistributorModal distributorId={selectedDistributor} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    distributors:
      (state?.ape?.distributors && Object.values(state.ape.distributors)) || [],
    loading: state.loader?.elements,
  };
};

export default connect(mapStateToProps, {
  apeFetchDistributors,
  apeDeleteDistributor,
  addNotice,
  showModal,
})(DistributorAdmin);
